.ecommerce-application .app-ecommerce-details .product-img {
  width: 600px;
}

.ecommerce-application .app-ecommerce-details .ratings-list-item svg, .ecommerce-application .app-ecommerce-details .ratings-list-item i {
  width: 1.286rem;
  height: 1.286rem;
  font-size: 1.286rem;
}

.ecommerce-application .app-ecommerce-details .filled-star {
  fill: #ff9f43;
  stroke: #ff9f43;
  color: #ff9f43;
}

.ecommerce-application .app-ecommerce-details .unfilled-star {
  stroke: #babfc7;
  color: #babfc7;
}

.ecommerce-application .app-ecommerce-details .item-price {
  color: #7367f0;
}

.ecommerce-application .app-ecommerce-details .item-company {
  font-size: .875rem;
  font-weight: 400;
  display: inline-flex;
}

.ecommerce-application .app-ecommerce-details .item-company .company-name {
  margin-left: .25rem;
  font-weight: 600;
}

.ecommerce-application .app-ecommerce-details .product-features {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.ecommerce-application .app-ecommerce-details .product-features li {
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.ecommerce-application .app-ecommerce-details .product-features li svg, .ecommerce-application .app-ecommerce-details .product-features li i {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: .75rem;
  font-size: 1.4rem;
}

.ecommerce-application .app-ecommerce-details .product-features li span {
  font-weight: 600;
}

.ecommerce-application .app-ecommerce-details .product-color-options {
  margin-top: 1.5rem;
  margin-bottom: 1.2rem;
}

.ecommerce-application .app-ecommerce-details .btn-wishlist .text-danger {
  color: #ea5455;
  fill: #ea5455;
}

.ecommerce-application .app-ecommerce-details .btn-share .btn-icon ~ .dropdown-menu {
  min-width: 3rem;
}

.ecommerce-application .app-ecommerce-details .item-features {
  background-color: #f8f8f8;
  padding-top: 5.357rem;
  padding-bottom: 5.357rem;
}

.ecommerce-application .app-ecommerce-details .item-features i, .ecommerce-application .app-ecommerce-details .item-features svg {
  color: #7367f0;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 2.5rem;
}

.ecommerce-application .swiper-responsive-breakpoints.swiper-container .swiper-slide {
  text-align: center;
  background-color: #f8f8f8;
  border-radius: .428rem;
  padding: 1.5rem 3rem;
}

.ecommerce-application .swiper-responsive-breakpoints.swiper-container .swiper-slide .img-container {
  justify-content: center;
  align-items: center;
  height: 250px;
  display: flex;
}

.ecommerce-application .swiper-button-prev, .ecommerce-application .swiper-button-next, .ecommerce-application .swiper-container-rtl .swiper-button-prev, .ecommerce-application .swiper-container-rtl .swiper-button-next {
  background-image: none;
  width: 35px;
  font-size: 2rem;
}

.ecommerce-application .swiper-button-prev:focus, .ecommerce-application .swiper-button-next:focus, .ecommerce-application .swiper-container-rtl .swiper-button-prev:focus, .ecommerce-application .swiper-container-rtl .swiper-button-next:focus {
  outline: none;
}

.ecommerce-application .swiper-button-prev {
  left: 0;
}

.ecommerce-application .swiper-button-prev:after {
  color: #6e6b7b;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%237367f0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 44px;
  width: 44px;
  height: 44px;
}

.ecommerce-application .swiper-button-next {
  right: 0;
}

.ecommerce-application .swiper-button-next:after, .ecommerce-application .swiper-container-rtl .swiper-button-prev:after {
  color: #6e6b7b;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%237367f0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 44px;
  width: 44px;
  height: 44px;
}

.ecommerce-application .swiper-container-rtl .swiper-button-next:after {
  color: #6e6b7b;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%237367f0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 44px;
  width: 44px;
  height: 44px;
}

.ecommerce-application .product-color-options .color-option {
  cursor: pointer;
  border: 1px solid #0000;
  border-radius: 50%;
  padding: 3px;
  position: relative;
}

.ecommerce-application .product-color-options .color-option .filloption {
  border-radius: 50%;
  width: 18px;
  height: 18px;
}

.ecommerce-application .product-color-options .selected .b-primary {
  border-color: #7367f0;
}

.ecommerce-application .product-color-options .selected .b-primary .filloption {
  box-shadow: 0 2px 4px #7367f066;
}

.ecommerce-application .product-color-options .selected .b-success {
  border-color: #28c76f;
}

.ecommerce-application .product-color-options .selected .b-success .filloption {
  box-shadow: 0 2px 4px #28c76f66;
}

.ecommerce-application .product-color-options .selected .b-danger {
  border-color: #ea5455;
}

.ecommerce-application .product-color-options .selected .b-danger .filloption {
  box-shadow: 0 2px 4px #ea545566;
}

.ecommerce-application .product-color-options .selected .b-warning {
  border-color: #ff9f43;
}

.ecommerce-application .product-color-options .selected .b-warning .filloption {
  box-shadow: 0 2px 4px #ff9f4366;
}

.ecommerce-application .product-color-options .selected .b-info {
  border-color: #00cfe8;
}

.ecommerce-application .product-color-options .selected .b-info .filloption {
  box-shadow: 0 2px 4px #00cfe866;
}

.ecommerce-application .product-color-options .b-primary .filloption {
  box-shadow: 0 2px 4px #7367f066;
}

.ecommerce-application .product-color-options .b-success .filloption {
  box-shadow: 0 2px 4px #28c76f66;
}

.ecommerce-application .product-color-options .b-danger .filloption {
  box-shadow: 0 2px 4px #ea545566;
}

.ecommerce-application .product-color-options .b-warning .filloption {
  box-shadow: 0 2px 4px #ff9f4366;
}

.ecommerce-application .product-color-options .b-info .filloption {
  box-shadow: 0 2px 4px #00cfe866;
}

@media (width <= 767.98px) {
  .ecommerce-application .swiper-responsive-breakpoints.swiper-container .swiper-slide {
    padding: 1rem;
  }

  .ecommerce-application .app-ecommerce-details .ratings-list-item svg, .ecommerce-application .app-ecommerce-details .ratings-list-item i {
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
  }
}
/*# sourceMappingURL=index.305624af.css.map */
